import React, { useContext, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import styled from 'styled-components'
import { Grommet, Box, ResponsiveContext, Tabs, Tab, Heading } from 'grommet'
import theme from '@lib/issues/theme'
import { IssueTable } from '@components/issues/issueTable'
import { LabelTable } from '@components/issues/labelTable'
import { IssueOptionButtons } from '@components/issues/issueOptionButtons'
import { LabelOptionButtons } from '@components/issues/labelOptionButtons'
import { BaseLayout } from '@layouts/base'

const GlobalStyles = styled(Grommet)`
  -webkit-font-smoothing: auto;

  * {
    vertical-align: top;
  }
  tr * {
    vertical-align: middle;
  }
  thead tr {
    height: 50px;
  }

  // Workaround for header border
  thead tr td {
    border-bottom: solid 0 transparent;
  }
`

const IssuesLayout: React.FC = () => {
  // Context with states small/medium/large
  const size = useContext(ResponsiveContext)

  return size === 'small' ? (
    <Box as='main' fill='vertical' flex='grow'>
      <Tabs>
        <Tab title='Issues'>
          <IssueOptionButtons />
          <IssueTable />
        </Tab>
        <Tab title='Labels'>
          <LabelOptionButtons />
          <LabelTable />
        </Tab>
      </Tabs>
    </Box>
  ) : (
    <Box as='main' fill='vertical' flex='grow' direction='row' pad={{ vertical: '2rem' }}>
      <Box basis='1/2' pad={{ left: '0', right: 'medium', bottom: 'small' }}>
        <Box align='center' direction='row' height={{ max: '6rem' }} pad={{ vertical: 'small' }}>
          <Heading level={3} size='large'>
            Issues
          </Heading>
          <IssueOptionButtons />
        </Box>
        <IssueTable />
      </Box>
      <Box
        basis='1/2'
        pad={{ left: 'medium', right: '0', bottom: 'small' }}
        border={{ side: 'left', size: '1px', color: '#c8c9cc' }}
      >
        <Box align='center' direction='row' height={{ max: '6rem' }} pad={{ vertical: 'small' }}>
          <Heading level={3} size='large'>
            Labels
          </Heading>
          <LabelOptionButtons />
        </Box>
        <LabelTable />
      </Box>
    </Box>
  )
}

const IssuesPage: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        staleTime: 1000 * 60 * 20, // 20 minutes
        cacheTime: 1000 * 60 * 60 * 24, // 1 day, must be equal or longer than maxAge in persistQueryClient
      },
    },
  })

  useEffect(() => {
    persistQueryClient({
      queryClient,
      // Local Storage persistence, max 1/minute
      persistor: createWebStoragePersistor({
        storage: window.localStorage,
        throttleTime: 1000,
      }),
      maxAge: 1000 * 60 * 60 * 24, // 1 day
      buster: 'issue-query-cache',
    })
  }, [])

  return (
    <BaseLayout pageIsLiquidContainer pageIsResponsive>
      <div className='container'>
        <QueryClientProvider client={queryClient}>
          <GlobalStyles theme={theme}>
            <IssuesLayout />
          </GlobalStyles>
        </QueryClientProvider>
      </div>
    </BaseLayout>
  )
}

export default IssuesPage
