import React, { useContext, useState } from 'react'
import { Box, Button, ResponsiveContext } from 'grommet'
import { Refresh } from 'grommet-icons'
import styled, { css, keyframes } from 'styled-components'
import { useQueryClient } from 'react-query'
import { categoryOptions, useCategoryAtom } from '@lib/issues/atoms'
import { ButtonGroup } from '@components/issues/buttonGroup'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg)
  }  
`
const rotateAnimation = css`
  animation: ${rotate} 2s infinite;
`
const RotatingRefreshButton = styled(Button)`
  svg {
    ${({ active }) => active && rotateAnimation}
  }
`

export const IssueOptionButtons: React.FC = () => {
  const [category, setCategory] = useCategoryAtom()
  const queryClient = useQueryClient()
  const size = useContext(ResponsiveContext)

  const [refreshActive, setRefreshActive] = useState<boolean>(false)
  return (
    <>
      <ButtonGroup
        buttons={[
          {
            label: 'All',
            active: category === categoryOptions.all,
            onClick: () => {
              setCategory(categoryOptions.all)
            },
          },
          {
            label: 'Features',
            active: category === categoryOptions.features,
            onClick: () => {
              setCategory(categoryOptions.features)
            },
          },
          {
            label: 'Bugs',
            active: category === categoryOptions.bugs,
            onClick: () => {
              setCategory(categoryOptions.bugs)
            },
          },
        ]}
      />
      {size !== 'small' && (
        <Box direction='row' margin={{ left: '2rem' }}>
          <RotatingRefreshButton
            label={<Refresh size='20px' color='text' />}
            active={refreshActive}
            onClick={() => {
              setRefreshActive(true)
              queryClient.invalidateQueries()
              queryClient.resetQueries('issues').then(() => {
                setRefreshActive(false)
              })
            }}
          />
        </Box>
      )}
    </>
  )
}
