import React, { useContext, useMemo, useState } from 'react'
import { Box, Text, Spinner, DataTable, ResponsiveContext } from 'grommet'
import { Badge } from '@components/issues/badge'
import { useFetchIssues } from '@lib/issues/issuesQuery'
import { IIssue, Status } from '@lib/issues/types'
import { categoryOptions, useCategoryAtom } from '@lib/issues/atoms'

const StatusDisplay = ({ status, labels }: IIssue) => {
  const statusLabel = labels.find((label) => label.name === status)
  return (
    <Badge
      text='&nbsp;'
      color={`#${statusLabel?.color}`}
      minWidth='20px'
      round='full'
      title={status}
    />
  )
}

const ScoreDisplay = ({ score }: IIssue) => <Badge text={`${score}`} color='border' />

const TitleDisplay = ({ title, labels, status }: IIssue) => {
  const size = useContext(ResponsiveContext)
  const labelMap = useMemo(
    () =>
      labels.map(
        ({ id, name, color, url }) =>
          name !== status && (
            <Badge
              text={name}
              color={`#${color}`}
              href={url.replace('api.github.com/repos', 'github.com')}
              key={id}
            />
          )
      ),
    [labels]
  )

  return (
    <Box direction='row' align='center' wrap>
      <Text>{title}</Text>
      {size !== 'small' && labelMap}
    </Box>
  )
}

export const IssueTable: React.FC = () => {
  const { data, error } = useFetchIssues()
  const [category] = useCategoryAtom()

  const [sortOrder, setSortOrder] = useState<{
    property: string
    direction: 'asc' | 'desc'
  }>()

  const localIssues = useMemo(() => {
    if (!data) return
    return data
      .filter(({ status }) => {
        if (category === categoryOptions.features)
          return [Status.accepted, Status.discussing].includes(status ?? '')
        else if (category === categoryOptions.bugs)
          return [Status.confirmed, Status.reproducing].includes(status ?? '')
        return true
      })
      .map((issue) => ({
        ...issue,
        statusVal: [
          '',
          Status.reproducing,
          Status.discussing,
          Status.confirmed,
          Status.accepted,
        ].indexOf(issue.status),
      }))
      .sort((a, b) => {
        if (sortOrder?.property === 'statusVal') {
          if (b.statusVal === a.statusVal) return b.score - a.score
          return sortOrder?.direction === 'asc'
            ? a.statusVal - b.statusVal
            : b.statusVal - a.statusVal
        }
        return sortOrder?.direction === 'asc' ? a.score - b.score : b.score - a.score
      })
  }, [category, data, sortOrder])

  const statusText = useMemo(() => {
    if (!localIssues) return
    if (category === categoryOptions.features) {
      return `${localIssues.filter(({ status }) => status === Status.accepted).length} Approved, ${
        localIssues.filter(({ status }) => status === Status.discussing).length
      } Discussing`
    } else if (category === categoryOptions.bugs) {
      return `${
        localIssues.filter(({ status }) => status === Status.confirmed).length
      } Confirmed, ${
        localIssues.filter(({ status }) => status === Status.reproducing).length
      } Reproducing`
    }
    return `${localIssues?.length} Total`
  }, [category, localIssues])

  return localIssues ? (
    <Box margin={{ left: '-0.5rem' }} width={{ max: 'unset' }}>
      <DataTable
        columns={[
          {
            header: 'Status',
            property: 'statusVal',
            sortable: true,
            align: 'start',
            size: '10%',
            render: StatusDisplay,
          },
          {
            header: 'Score',
            property: 'score',
            sortable: true,
            align: 'start',
            size: '10%',
            render: ScoreDisplay,
          },
          {
            header: (
              <Box direction='row'>
                <Text weight='bold'>Title</Text>
                <Text size='small' margin={{ left: 'auto', right: '-0.35rem' }}>
                  {statusText}
                </Text>
              </Box>
            ),
            property: 'title',
            primary: true,
            sortable: false,
            size: '80%',
            render: TitleDisplay,
          },
        ]}
        onClickRow={({ datum }) => {
          window?.open(datum.html_url)
        }}
        onSort={(sort) => {
          setSortOrder(sort)
        }}
        pad='xsmall'
        sort={{ direction: 'desc', property: 'score', external: true }}
        data={localIssues}
        fill='horizontal'
      />
    </Box>
  ) : (
    <Box align='center' justify='center' pad={{ vertical: 'xlarge' }}>
      <Spinner message='Loading...' size='large' />
      {error && <Text>{(error as Error).message}</Text>}
    </Box>
  )
}
