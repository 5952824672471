import { atom, useAtom, SetStateAction } from 'jotai'

// Category Atom
export const categoryOptions = {
  all: 'all',
  features: 'features',
  bugs: 'bugs',
}
const categoryAtom = atom('all')
export const useCategoryAtom = (): [
  string,
  (update: SetStateAction<string>) => void | Promise<void>
] => useAtom(categoryAtom)

// Label Display Atom
export const labelDisplayOptions = {
  breakdown: 'breakdown',
  description: 'description',
}
const labelDisplayAtom = atom('breakdown')
export const useLabelDisplayAtom = (): [
  string,
  (update: SetStateAction<string>) => void | Promise<void>
] => useAtom(labelDisplayAtom)

// Label Group Atom
const labelGroupAtom = atom(false)
export const useLabelGroupAtom = (): [
  boolean,
  (update: SetStateAction<boolean>) => void | Promise<void>
] => useAtom(labelGroupAtom)
