import React, { useContext } from 'react'
import { Box, CheckBox, ResponsiveContext } from 'grommet'
import { labelDisplayOptions, useLabelDisplayAtom, useLabelGroupAtom } from '@lib/issues/atoms'
import { ButtonGroup } from '@components/issues/buttonGroup'

export const LabelOptionButtons: React.FC = () => {
  const size = useContext(ResponsiveContext)

  const [labelDisplay, setLabelDisplay] = useLabelDisplayAtom()
  const [labelGroup, setLabelGroup] = useLabelGroupAtom()
  return (
    <Box
      direction='row'
      justify={size === 'small' ? 'center' : 'end'}
      margin={{ left: 'auto', bottom: size === 'small' ? '3rem' : undefined }}
    >
      <Box justify='center' margin={{ right: '2rem' }}>
        <CheckBox
          label='Group'
          onChange={() => {
            setLabelGroup((val) => !val)
          }}
          checked={labelGroup}
        />
      </Box>
      <ButtonGroup
        buttons={[
          {
            label: 'Breakdown',
            active: labelDisplay === labelDisplayOptions.breakdown,
            onClick: () => {
              setLabelDisplay(labelDisplayOptions.breakdown)
            },
          },
          {
            label: 'Description',
            active: labelDisplay === labelDisplayOptions.description,
            onClick: () => {
              setLabelDisplay(labelDisplayOptions.description)
            },
          },
        ]}
        margin={{}}
      />
    </Box>
  )
}
