import React from 'react'
import { Anchor, Box, Text } from 'grommet'

interface IBadgeProps {
  text: string
  title?: string
  color?: string | 'transparent'
  width?: string | undefined
  textAlign?: 'start' | 'center' | 'end'
  round?: string
  minWidth?: string | undefined
  maxWidth?: string | undefined
  href?: string
}

export const Badge: React.FC<IBadgeProps> = ({
  text,
  title,
  color,
  width,
  textAlign,
  round,
  minWidth,
  maxWidth,
  href,
}: IBadgeProps) => (
  <Anchor href={href} target='_blank' weight='normal'>
    <Box
      background={color}
      margin='xxsmall'
      pad='0 7px'
      round={round || 'medium'}
      border={{ size: 'xsmall', style: 'solid', color: 'transparent' }}
      style={{ width }}
      width={{ min: minWidth, max: maxWidth }}
      title={title || text}
      onClick={(e: Event) => {
        e.stopPropagation()
      }}
    >
      <Text size='small' textAlign={textAlign || 'center'} a11yTitle={title || text}>
        {text}
      </Text>
    </Box>
  </Anchor>
)
