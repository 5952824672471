import axios, { AxiosResponse } from 'axios'
import { IIssue } from '@lib/issues/types'
import { mapIssues } from '@lib/issues/issuesDataManipulation'
import { useQuery } from 'react-query'

interface IFetchIssueError {
  message: string
  documentation_url: string
}

type IFetchIssue = AxiosResponse<Array<IIssue> | IFetchIssueError>

// Constants
const repo = 'fullcalendar/fullcalendar'
const pageCount = 100

// Utility function to get promise based on page
const getRequest = (page: number) => {
  const endpoint = `https://api.github.com/repos/${repo}/issues?per_page=${pageCount}&page=${page}`
  return axios.get<never, IFetchIssue>(endpoint, {
    headers: {
      accept: 'application/vnd.github.squirrel-girl-preview',
    },
  })
}

export const fetchAllIssues = async (): Promise<IIssue[]> => {
  // Fetch page 1
  const { data: dataP1, headers: headersP1 }: IFetchIssue = await getRequest(1)

  // Type checking
  if (!Array.isArray(dataP1)) throw new Error(`${dataP1.message} ${dataP1.documentation_url}`)

  // Storage Array
  let dataArr: IIssue[] = [...dataP1]

  // Get last page # from header
  const lastUrlMeta = headersP1.link.split(',')[1].trim()
  const lastUrl = lastUrlMeta.match(/<([^>]*)>/)[1]
  const lastUrlObj = new URL(lastUrl)
  const lastPage = parseInt(lastUrlObj.searchParams.get('page') || '1')

  // if (headersP1.xRatelimitRemaining) throw new Error('No Requests Remaining')

  // Create all promises for requests
  let batchList = new Array<Promise<IFetchIssue>>()
  for (let p = 2; p <= lastPage; p++) {
    batchList = [...batchList, getRequest(p)]
  }

  // Execute batched XHR requests
  const batchPages = await axios.all(batchList)
  batchPages.forEach(({ data }) => {
    if (!Array.isArray(data)) throw new Error(`${data.message} ${data.documentation_url}`)
    dataArr = [...dataArr, ...data]
  })

  // Remove Pull Requests
  return dataArr.filter(({ pull_request }) => !pull_request)
}

export const useFetchIssues = (): { data?: IIssue[]; error: Error | null } => {
  const { data, error } = useQuery<IIssue[], Error>('issues', fetchAllIssues)
  return { data: data && mapIssues(data), error }
}
