import React, { useMemo } from 'react'
import { Box, Text, Spinner, DataTable, ColumnConfig } from 'grommet'
import { useFetchIssues } from '@lib/issues/issuesQuery'
import { Status, ILabel } from '@lib/issues/types'
import {
  useCategoryAtom,
  categoryOptions,
  useLabelDisplayAtom,
  useLabelGroupAtom,
} from '@lib/issues/atoms'
import { getGroupByName, mapLabels } from '@lib/issues/issuesDataManipulation'
import { Badge } from '@components/issues/badge'

const DescriptionDisplay: React.FC<ILabel> = ({ id, description, groupName }: ILabel) => (
  <Text>{id === undefined ? getGroupByName(groupName)?.description : description}</Text>
)

const LabelDisplay: React.FC<ILabel> = (label: ILabel) => {
  const { groupName, id, color, name, url } = label
  return id === undefined ? (
    <Text>{groupName}</Text>
  ) : (
    <Box margin={{ left: 'auto' }}>
      <Badge
        text={name}
        color={`#${color}`}
        href={url.replace('api.github.com/repos', 'github.com')}
      />
    </Box>
  )
}

export const LabelTable: React.FC = () => {
  const { data, error } = useFetchIssues()

  const [category] = useCategoryAtom()
  const [labelDisplay] = useLabelDisplayAtom()
  const [labelGroup] = useLabelGroupAtom()

  const localLabels = useMemo(
    () =>
      data &&
      mapLabels(
        data.filter(({ status }) => {
          if (category === categoryOptions.features)
            return [Status.accepted, Status.discussing].includes(status ?? '')
          else if (category === categoryOptions.bugs)
            return [Status.confirmed, Status.reproducing].includes(status ?? '')
          return true
        })
      ).map((label) => ({
        ...label,
        tracker: labelGroup ? label.groupScore : label.score,
      })),
    [data, category, labelGroup]
  )

  const maxScore = useMemo(
    () => localLabels?.reduce((acc, { tracker }) => (tracker > acc ? tracker : acc), 0),
    [localLabels]
  )

  // Local scoping due to needing access to maxScore variable
  const ScoreDisplay = ({ score }: ILabel) => {
    if (!maxScore || !data) return
    const percent = (score / maxScore) * 100
    return (
      data && (
        <Badge
          text={`${score}`}
          color='border'
          width={`${percent}%`}
          textAlign='start'
          minWidth='fit-content'
        />
      )
    )
  }

  const columns: Array<ColumnConfig<ILabel>> = [
    {
      property: 'id',
      primary: true,
      size: labelGroup ? '25%' : '30%',
      sortable: false,
      header: (
        <Text textAlign={labelGroup ? 'start' : 'end'} margin={{ right: 'xxsmall' }} weight='bold'>
          {labelGroup ? 'Group' : 'Name'}
        </Text>
      ),
      render: LabelDisplay,
    },
    {
      property: labelDisplay === 'breakdown' ? 'score' : 'description',
      header: (
        <Box direction='row'>
          <Text weight='bold'>{labelDisplay === 'breakdown' ? 'Total Score' : 'Description'}</Text>
          <Text size='small' margin={{ left: 'auto' }}>
            &nbsp;
            {
              {
                all: '',
                features: 'Filtered for Features',
                bugs: 'Filtered for Bugs',
              }[category]
            }
          </Text>
        </Box>
      ),
      sortable: false,
      aggregate: 'sum',
      render: labelDisplay === 'breakdown' ? ScoreDisplay : DescriptionDisplay,
    },
  ]

  return localLabels ? (
    <Box margin={{ left: '-0.5rem', right: '-0.35rem' }} width={{ max: 'unset' }}>
      <DataTable
        columns={columns}
        key={`labelTable-${labelGroup ? 'grouped' : 'ungrouped'}`}
        groupBy={labelGroup ? 'groupName' : undefined}
        data={localLabels}
        sort={{
          direction: 'desc',
          property: 'tracker',
        }}
        fill='horizontal'
        pad='xsmall'
      />
    </Box>
  ) : (
    <Box align='center' justify='center' pad={{ vertical: 'xlarge' }}>
      <Spinner message='Loading...' size='large' />
      {error && <Text>{(error as Error).message}</Text>}
    </Box>
  )
}
