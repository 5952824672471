import React, { useContext, useMemo } from 'react'
import { Box, Button, ResponsiveContext } from 'grommet'
import { MarginType } from 'grommet/utils'
import styled from 'styled-components'

const StyledButtonGroup = styled(Button)`
  font-weight: 400;
  &:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: transparent;
  }
`

interface IButtonProps {
  label: string
  active: boolean
  onClick: () => void
}

interface IButtonGroupProps {
  buttons: Array<IButtonProps>
  margin?: MarginType
}

export const ButtonGroup: React.FC<IButtonGroupProps> = ({
  buttons,
  margin,
}: IButtonGroupProps) => {
  const size = useContext(ResponsiveContext)
  const kids = useMemo(
    () =>
      buttons.map(({ label, active, onClick }, index) => (
        <StyledButtonGroup label={label} active={active} onClick={onClick} key={index} />
      )),
    [buttons]
  )

  return (
    <Box
      justify={size === 'small' ? 'center' : 'end'}
      direction='row'
      margin={
        margin || {
          left: 'auto',
          bottom: size === 'small' ? '3rem' : undefined,
        }
      }
    >
      {kids}
    </Box>
  )
}
