export const Status = {
  accepted: 'Accepted',
  confirmed: 'Confirmed',
  reproducing: 'Reproducing',
  discussing: 'Discussing',
}

export interface IIssue {
  title: string
  id: number
  url: string
  html_url: string
  labels: Array<ILabel>
  comments: number
  created_at: Date
  body: string
  reactions: {
    total_count: number
    '+1': number
    '-1': number
    laugh: number
    hooray: number
    confused: number
    heart: number
    rocket: number
    eyes: number
  }
  pull_request: {
    url: string
  }
  score: number | 0
  status: string | ''
}

export interface ILabel {
  id: number
  node_id: string
  url: string
  name: string
  color: string
  default: boolean
  description: string
  score: number | 0
  groupName: string | 'Unknown'
  groupScore: number | 0
}

export interface IGroup {
  name: string
  description: string
  labels: Array<string>
}
