import groups from '@lib/issues/groups.json'
import { IGroup, IIssue, ILabel, Status } from '@lib/issues/types'

export const getIssueScore = ({ body, comments, reactions }: IIssue): number => {
  const { total_count: reactionCount, '-1': minusOne, confused } = reactions

  // RegEx to get legacy stars
  const legacyStarsMatch = body.match(/Imported with \*\*(\d+)\*\* stars/)
  const legacyStars = legacyStarsMatch ? parseInt(legacyStarsMatch[1]) : 0

  // Equation for calculating score
  return ~~(reactionCount - 2 * (minusOne + confused) + 0.5 * legacyStars + 0 * comments)
}

export const getIssueStatus = ({ labels }: IIssue): string =>
  labels.find((label) => Object.values(Status).includes(label.name))?.name ?? ''

// Find group for a specific label
export const getLabelGroup = ({ name }: ILabel): IGroup | undefined =>
  groups.find(({ labels }) => labels.includes(name))

export const getGroupByName = (name: string): IGroup | undefined =>
  groups.find(({ name: groupName }) => name === groupName)

export const mapLabels = (issues: Array<IIssue>): Array<ILabel> => {
  const mapped = Object.values(
    issues.reduce((acc: { [key: string]: ILabel }, issue) => {
      issue.labels.forEach((label) => {
        const { name } = label
        const group = getLabelGroup(label)
        acc[name] = {
          ...label,
          score: (acc[name]?.score ?? 0) + getIssueScore(issue),
          groupName: group?.name ?? 'Unknown',
        }
      })
      return acc
    }, {})
  ).filter(({ name }) => !Object.values(Status).includes(name))

  const groupPoints = mapped.reduce((acc: { [key: string]: number }, { groupName, score }) => {
    acc[groupName] = (acc[groupName] ?? 0) + score
    return acc
  }, {})

  return mapped.map(({ score, groupName, ...rest }) => ({
    score,
    groupName,
    ...rest,
    groupScore: groupPoints[groupName] + score / groupPoints[groupName],
  }))
}

export const mapIssues = (issues: Array<IIssue>): Array<IIssue> =>
  issues.map((issue) => ({
    ...issue,
    score: getIssueScore(issue),
    status: getIssueStatus(issue),
  }))
