import { ThemeType } from 'grommet'

export const theme: ThemeType = {
  global: {
    colors: {
      brand: '#61dafb',
      background: {
        dark: '#111',
        light: '#FFF',
      },
      'background-back': {
        dark: '#111',
        light: '#EEE',
      },
      'background-front': {
        dark: '#222',
        light: '#FFF',
      },
      'background-contrast': {
        dark: '#FFF1',
        light: '#1111',
      },
      text: {
        dark: '#FFF',
        light: '#000',
      },
      'text-strong': {
        dark: '#FFF',
        light: '#000',
      },
      'text-weak': {
        dark: '#CCC',
        light: '#444',
      },
      'text-xweak': {
        dark: '#999',
        light: '#666',
      },
      border: '#20232a',
      control: 'brand',
      'active-background': 'background-contrast',
      'active-text': 'text-strong',
      'selected-background': 'brand',
      'selected-text': 'text-strong',
      'status-critical': '#FF4040',
      'status-warning': '#FFAA15',
      'status-ok': '#00C781',
      'status-unknown': '#CCCCCC',
      'status-disabled': '#CCCCCC',
      'graph-0': 'brand',
      'graph-1': 'status-warning',
    },
    font: {
      family: 'unset',
      size: '14px',
      height: '1.5em',
      maxWidth: '300px',
    },
    active: {
      background: 'brand',
      color: 'active-text',
    },
    hover: {
      background: 'active-background',
      color: 'active-text',
    },
    selected: {
      background: 'selected-background',
      color: 'selected-text',
    },
    focus: {
      border: {
        color: 'transparent',
      },
      outline: {
        color: 'transparent',
        size: '0',
      },
      shadow: {
        color: 'transparent',
        size: '0',
      },
    },
    control: {
      border: {
        radius: '0',
      },
    },
    drop: {
      border: {
        radius: '0px',
      },
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '3px',
      large: '10px',
      xlarge: '20px',
    },
    breakpoints: {
      small: {
        value: 768,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '3px',
          large: '5px',
          xlarge: '10px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '0.5rem',
          medium: '1rem',
          large: '1.5rem',
          xlarge: '2rem',
        },
        size: {
          xxsmall: '1rem',
          xsmall: '2rem',
          small: '4rem',
          medium: '10rem',
          large: '20rem',
          xlarge: '40rem',
          full: '100%',
        },
      },
      medium: {
        value: 1280,
      },
      large: {},
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '0.25em',
      xsmall: '0.5em',
      small: '1em',
      medium: '2em',
      large: '3.5em',
      xlarge: '6em',
      responsiveBreakpoint: 'small',
    },
    input: {
      padding: '10px',
      weight: 900,
    },
    spacing: '1.5rem',
    size: {
      xxsmall: '2rem',
      xsmall: '5rem',
      small: '10rem',
      medium: '20rem',
      large: '40rem',
      xlarge: '60rem',
      xxlarge: '80rem',
      full: '100%',
    },
  },
  dataTable: {
    header: {
      font: {
        weight: 'bold',
      },
      border: {
        color: 'transparent',
      },
    },
  },
  tabs: {
    header: {
      extend: 'padding-top: 3em; padding-bottom: 3em;',
    },
  },
  tab: {
    color: 'text',
    border: {
      color: 'transparent',
      active: {
        color: 'border',
      },
      hover: {
        color: 'brand',
      },
    },
  },
  button: {
    default: {
      border: { color: 'brand', width: '0.125rem' },
      font: { weight: 400 },
    },
    border: {
      width: '0.125rem',
      radius: '0.25rem',
    },
    active: {
      background: { color: 'brand' },
    },
    hover: {
      background: { color: 'brand' },
    },
  },
  checkBox: {
    check: {
      radius: '0px',
    },
    toggle: {
      radius: '0px',
      size: '40px',
    },
    size: '1.25rem',
  },
  heading: {
    weight: 900,
    level: {
      1: {
        small: {
          size: '1.75rem',
          height: '2rem',
          maxWidth: '567px',
        },
        medium: {
          size: '2.625rem',
          height: '3rem',
          maxWidth: '833px',
        },
        large: {
          size: '4.25rem',
          height: '4.5rem',
          maxWidth: '1367px',
        },
        xlarge: {
          size: '6rem',
          height: '6.25rem',
          maxWidth: '1900px',
        },
      },
      2: {
        small: {
          size: '1.5rem',
          height: '2rem',
          maxWidth: '500px',
        },
        medium: {
          size: '2.25rem',
          height: '2.5rem',
          maxWidth: '700px',
        },
        large: {
          size: '2.75rem',
          height: '3.2rem',
          maxWidth: '900px',
        },
        xlarge: {
          size: '3.5rem',
          height: '3.75',
          maxWidth: '1100px',
        },
      },
      3: {
        small: {
          size: '1.4rem',
          height: '1.75rem',
          maxWidth: '433px',
        },
        medium: {
          size: '1.6rem',
          height: '2rem',
          maxWidth: '567px',
        },
        large: {
          size: '2.125rem',
          height: '2.5rem',
          maxWidth: '700px',
        },
        xlarge: {
          size: '2.625rem',
          height: '3rem',
          maxWidth: '833px',
        },
      },
      4: {
        small: {
          size: '1rem',
          height: '1.5rem',
          maxWidth: '367px',
        },
        medium: {
          size: '1.5rem',
          height: '1.75rem',
          maxWidth: '433px',
        },
        large: {
          size: '1.7rem',
          height: '2rem',
          maxWidth: '500px',
        },
        xlarge: {
          size: '1.75rem',
          height: '2rem',
          maxWidth: '567px',
        },
      },
      5: {
        small: {
          size: '0.825rem',
          height: '1.125rem',
          maxWidth: '267px',
        },
        medium: {
          size: '0.825rem',
          height: '1.125rem',
          maxWidth: '267px',
        },
        large: {
          size: '0.825rem',
          height: '1.125rem',
          maxWidth: '267px',
        },
        xlarge: {
          size: '0.825rem',
          height: '1.125rem',
          maxWidth: '267px',
        },
      },
      6: {
        small: {
          size: '0.75rem',
          height: '1rem',
          maxWidth: '233px',
        },
        medium: {
          size: '0.75rem',
          height: '1rem',
          maxWidth: '233px',
        },
        large: {
          size: '0.75rem',
          height: '1rem',
          maxWidth: '233px',
        },
        xlarge: {
          size: '0.75rem',
          height: '1rem',
          maxWidth: '233px',
        },
      },
    },
  },
  paragraph: {
    small: {
      size: '0.8rem',
      height: '1.2rem',
      maxWidth: '267px',
    },
    medium: {
      size: '1rem',
      height: '1.3rem',
      maxWidth: '300px',
    },
    large: {
      size: '1.1rem',
      height: '1.5rem',
      maxWidth: '367px',
    },
    xlarge: {
      size: '1.5rem',
      height: '1.75rem',
      maxWidth: '433px',
    },
    xxlarge: {
      size: '1.75rem',
      height: '2rem',
      maxWidth: '567px',
    },
  },
  text: {
    xsmall: {
      size: '0.75em',
      height: '16px',
      maxWidth: '233px',
    },
    small: {
      size: '0.85em',
      height: '18px',
      maxWidth: '267px',
    },
    medium: {
      size: '1em',
      height: '20px',
      maxWidth: '300px',
    },
    large: {
      size: '1.1em',
      height: '24px',
      maxWidth: '367px',
    },
    xlarge: {
      size: '1.5em',
      height: '28px',
      maxWidth: '433px',
    },
    xxlarge: {
      size: '1.75em',
      height: '32px',
      maxWidth: '567px',
    },
  },
  anchor: {
    hover: {
      textDecoration: 'none',
    },
  },
}

export default theme
